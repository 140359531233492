@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
:root {
  --moving_left_spacing: 0px;
  --moving_width: 66px;

  --dark: #183c40;
  --primary: #295f65;
  --secondary: #3c7f7a;
  --light: #f7edde;

  --gap: 256px;
  --gap-images: 24px;
  --project-image-height: 300px;

  --default_transition: all 200ms ease-in-out;

  --header-color: #efe6d9;
  --max-width: 1600px;
  --burger: var(--dark);
  --burger-stable: var(--dark);

  --only-desktop-margin: 40px;
}


body {
  background-color: var(--light);

}

.App-Container {
  min-height: 100vh;
  overflow-x: hidden;
}

.App-Container > * {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  max-width: var(--max-width);
}

a {
  color: var(--primary);
}

* {
  margin: 0px;
  padding: 0px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary);
  margin: 0px;
  padding: 0px;
  max-width: max-content;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;  
  color: var(--secondary);
  margin: 0px;
  padding: 0px;
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--dark);
}

p.bigger{
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--dark);
}

.SectionTitle{
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid lightgrey;
}

.HyperLinks{
  color: var(--primary);
  text-decoration: underline;
  text-decoration-color: var(--primary);
  transition: var(--default_transition);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hide{
  z-index: -100 !important;
}

.show{
  visibility: visible;
}

.ShowIfMobile{
  display: none;
  width: 0;
  height: 0;
}

.ShowIfDesktop{
  display: flex;
  width: fit-content;
  height: fit-content;
}


.PhotoCredit {
  font-size: 0.4em; /* Slightly smaller than standard text */
  color: #ffffff; /* White text for visibility */
  padding: 6px 10px; /* Balanced padding for better readability */
  position: absolute;
  bottom: 10px; /* Slightly above the bottom edge */
  left: 10px; /* Aligned with the left edge */
  border-radius: 4px; /* Soft rounded corners for a modern look */
  z-index: 2; /* Ensures the credit is above other elements */
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8); /* Improve readability on bright images */
  font-family: 'Arial', sans-serif; /* Clean and simple font */
  white-space: nowrap; /* Ensure text is in a single line */
  overflow: hidden; /* Trim any overflow */
  text-overflow: ellipsis; /* Add ellipsis for long credits */
}

@media screen and (max-width: 1148px) {
  :root {
    --gap: 128px;
    --project: 225px;
  }
}

@media screen and (max-width: 780px) {
  :root{
    --only-desktop-margin: 0px;
  }

  .ShowIfMobile{
    display: block;
    width: fit-content;
    height: fit-content;
  }

  .ShowIfDesktop{
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  
  
  :root {
    --project: 200px;
  }
}

@media screen and (max-width: 680px) {
  :root {
    --project: 150px;
  }
}

@media screen and (max-width: 540px) {
  :root {
    --project: 125px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --project: 100px;
  }
}