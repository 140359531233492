/* ProjectDetails.css */
.ProjectDetails-Container {
    margin-top: 1rem;
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ProjectDetails-Container > * > * {
    font-size: 1rem;
    color: black;
    margin-bottom: 1rem;
}


strong {
    font-weight: bold;
}

.ProjectDetails-ExternalLink a {
    color: var(--primary);
    text-decoration: underline;
    font-weight: bold;
}

.ProjectDetails-ExternalLink a:hover {
    text-decoration: underline;
}

.ImageGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
}


.ErrorTitle {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #e74c3c;
}

.ErrorMessage {
    font-size: 1rem;
    line-height: 1.6;
}

.ErrorMessage a {
    color: var(--primary);
    text-decoration: underline;
}

.ErrorMessage a:hover {
    text-decoration: underline;
}

/* Publications section styling */
.ProjectDetails-Publications {
    margin-top: 1.5rem;
}

/*Select all but not first */
.ProjectDetails-Publications > *:not(:first-child) {
    margin-top: 1rem;
    margin-left: 1.5rem;
}

.ProjectDetails-Publications ul {
    list-style-type: none; /* Remove default bullets */
    padding-left: 1.5rem; /* Add indentation */
}

.ProjectDetails-Publications li {
    position: relative;
    margin-bottom: 0.8rem;
    line-height: 1.6;
}

.ProjectDetails-Publications li::before {
    content: counter(item) "."; /* Add counter for numeration */
    counter-increment: item; /* Increment counter */
    font-weight: bold;
    color: #333;
    position: absolute;
    left: -1.5rem; /* Adjust position to align with indentation */
}

/* Initialize counter for the list */
.ProjectDetails-Publications ul {
    counter-reset: item;
}
