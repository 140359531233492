.Header-Container-Wrapper{
    width: 100%;
}

.Header-Container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: min-content ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    gap: 40px;
    height: 100%;
}

.Header-Container > span {
    background-color: var(--header-color) ;
}

.Header-Container > * > button {
    color: var(--header-color) !important;
}

.Header-Container-Wrapper{
    position: relative;
    width: auto;
}

.Header-MovingBar{
    position: absolute;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    height: 2px;
    min-width: var(--moving_width);
    width: var(--moving_width);
    max-width: var(--moving_width);
    top: calc(40px + 30px);
    left: 0;
    background-color: var(--primary);
    transform: translate(var(--moving_left_spacing));
    color: transparent;
    transition: all 0.2s ease-in-out;
}

.Header-MovingBar.MoveToPublications{
    left: 100px !important;
}

.Footer-Container{
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    padding-top: 40px;
    gap: var(--gap);
    width: auto;
    height: 100%;
    padding-bottom: 128px;
}

.Footer-BottomContainer{
    padding-top: 8px;
    position: fixed;
    width: fit-content;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    right: 16px;
    height: 112px;
    width: fit-content;
    gap: 16px;
    background-color: var(--primary);
    color: var(--light);
    border-radius: 2px 2px 0px 0px;
    transform: translateY(84px);
    transition: var(--default_transition);
    z-index: 100;
}

.Footer-BottomContainer:hover{
    transform: translateY(0px);
}

.Footer-BottomContainer:hover > a > p{
    color: var(--light)
}


.Footer-BottomContainer > :first-child{
    text-decoration-color: transparent;
    cursor: default;
    color: var(--light);
}

.Footer-BottomContainer-P{
    padding-left: 8px;
    padding-right: 8px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    color: transparent;
    text-align: center !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: var(--default_transition);

}

.Footer-BottomContainer-P:hover{
    text-decoration: underline;
    text-decoration-color: var(--light);
}

.HeaderMobilePanel{
    position: absolute;
    top: 0;
    left: 0%;
    height: 100vh;
    width: 100vw;
    background-color: var(--light);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
}

.panelOpen{
    transform: translateX(0%);
}

.HeaderMobilePanel-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: all 0.2s ease-in-out;
}

.BurgerButton{
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    z-index: 1000;
    cursor: pointer;
}



@media screen and (max-width: 780px) {
    .Header-Container{
        scale: 1;
    }

    .Footer-Container{
        scale: 1;
        align-items: center;
        justify-content: center;
        padding-bottom: 64px;
    }
}

@media screen and (max-width: 500px) {
    .Header-Container{
        
        scale: 1;
    }

    .Footer-Container {
        scale: 1;
    }
}
