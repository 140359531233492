/* Main container styling */
.ProjectsPage-Container {
    padding: 21x;
}
/* Projects grid layout */
.ProjectsGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; /* Spgapace between the cards */
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

/* Project card styling */
.ProjectCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 432px; /* Adjust based on desired height */
    color: #000;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
    position: relative;
    background-color: #1D4E89; 
}


.ProjectTitle{
    font-size: 1.4rem;
    text-align: center;
    color: white;
    text-shadow: 
    1px 1px 0 black, 
   -1px 1px 0 black, 
    1px -1px 0 black, 
   -1px -1px 0 black;
}

.ProjectCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}


/* Link styling */
.ProjectLink {
    margin-top: 10px;
    font-size: 0.9em;
    color: var(--primary); /* Use a CSS variable or replace with a specific color */
    text-decoration: underline;
    position: absolute;
    bottom: 20px;
}


@media (max-width: 768px) {

    .ProjectCard {
        width: 270px;
    }
}
