.Media-Background{
    min-height: 100vh;
    min-width: 100vw;
    padding: 0;
    margin: 0;
    height: fit-content;
    margin-top: var(--only-desktop-margin);
    /* background-image: url('/public/images/b3_blured.webp');
    background-repeat: no-repeat;
    background-size: cover;
} */
}

.Media-item{
    width: 300px;
    height: 300px;
    border-radius: 2px;
    overflow: hidden;
}

.Media-Container{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
}

.Description-Soundcsapes{
    display: flex;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
    width: fit-content;
    height: 100%;
    z-index: 10;
}

/* .Media-Container > p {
    color: white;
} */

.absoluteimage{
    position: absolute;
    top: 100px;
    width: 100%;
    height: max-content;
    object-fit: cover;
    z-index: -1;
}

.Media-Container-Items{
    display: flex;
    flex-direction: row;
    gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    z-index: 10;
}