button{
    border: 0px;
    background-color: transparent;

    /* text properties */
    color: var(--dark);
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;

    transition: var(--default_transition);

    text-decoration: underline;
    text-decoration-color: transparent;

    cursor: pointer;
}

button:hover {
    color: var(--primary);

}

button.active{
    color: var(--primary);
    /* text-decoration-color: var(--primary); */
}