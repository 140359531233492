#background-video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    float: left;
    top: 0;
    left: 0;
    padding: none;
    z-index: -1;
    position: absolute; /* optional depending on what you want to do in your app */
}

.EmptyFullScreenDiv{
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    height: calc(100vh - 71px); /* 71px is the height of the header */
}

.CharlesTyper{
    display: flex;
    position: absolute;
    flex-direction: column;
    margin-top: 62vh;
}

.CharlesTyper > h1 {
    font-family: 'Cutive Mono';
    font-size: 50px !important;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: 0em;
    color: var(--light);
    margin: 0px;
    padding: 0px;
    max-width: max-content;
}

.CharlesTyper > h2 {
    font-family: 'Cutive Mono';
    font-size: 30px !important;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: 0em;
    color: var(--light);
    margin: 0px;
    padding: 0px;
    max-width: max-content;
  }

  .NewMain-Container-Start{
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .MyInterestsContainer{
    margin: 0;
    padding: 0;
  }
  .MyInterestsContainer > p{
    margin: 0;
    padding: 0;
  }
