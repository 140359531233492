/* Main background styling */
.Mentoring-Background {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px !important;
    min-height: 100%;
}

/* Google Form container */
.Google-Form-Container {
    flex: 1;
    border: none;
    width: 100%; 
    min-height: 100vh;
    padding: 0px !important;
    border-radius: 8px;
}

/* Responsive design */
@media (max-width: 768px) {
    .Google-Form-Container {
        width: 90%;
        height: 100%; /* Smaller height for mobile screens */
    }
}
